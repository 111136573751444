import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// Components
import JaLayout from '../../components/JaLayout';

// Images
import Gallery from '../../img/gallery.gif';

// Language
import { getPackOf } from '../../lang';
const LANG = getPackOf('ja');

const Search = (props) => {
  const data = useStaticQuery(graphql`
    query {
      products: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/products-ja/**.md" } }) {
        edges {
          node {
            frontmatter {
              title
              imgDescription
              urlRoute
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
      cases: allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/case-ja/**.md" } }) {
        edges {
          node {
            frontmatter {
              title
              thumbnail {
                publicURL
              }
              caseCategory
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  // Get search query from url
  const searchQuery = new URLSearchParams(props.location.search).get('query');
  const filteredProducts = data.products.edges.filter((edge) => {
    const {
      node: {
        frontmatter: { title, imgDescription },
        excerpt,
      },
    } = edge;
    if (searchQuery) {
      return (
        (title && title.includes(searchQuery)) ||
        (excerpt && excerpt.includes(searchQuery)) ||
        (imgDescription && imgDescription.includes(searchQuery))
      );
    }
  });
  const filteredCases = data.cases.edges.filter((edge) => {
    const {
      node: {
        frontmatter: { title, caseCategory },
        excerpt,
      },
    } = edge;
    if (searchQuery) {
      return (
        (title && title.includes(searchQuery)) ||
        (excerpt && excerpt.includes(searchQuery)) ||
        (caseCategory && caseCategory.includes(searchQuery))
      );
    }
  });

  return (
    <JaLayout>
      <section id="page_bn" className="pb-1" style={{ backgroundColor: ' #efefef', marginTop: '-16px' }}>
        <div className="container">
          <div id="breadcrumb">
            <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
              <div className="">
                <div className="col-12 pt-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                      <li className="breadcrumb-item">
                        <Link to="/" style={{ color: '#fff' }}>
                          {LANG.首頁}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/ja/case" style={{ color: '#fff' }}>
                          {LANG.成功案例}
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="result" className="my-3">
        <div className="container ">
          <div className="row">
            <div className="col-12 text-left">
              <p>
                {LANG.搜尋結果字串1}
                {searchQuery}
                {LANG.搜尋結果字串2}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="result_case" className="my-3">
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <p className="h3">
                <strong>{LANG.太陽能支架}</strong>
              </p>
            </div>
            {filteredProducts.length === 0 && (
              <div className="col-12 my-1 align-items-center">
                <p>{LANG.未找到相符的內容}</p>
              </div>
            )}
            {filteredProducts.map(
              ({
                node: {
                  frontmatter: { title, urlRoute },
                  excerpt,
                },
              }) => {
                return (
                  <div className="col-12 my-1 align-items-center" key={title}>
                    <div className="row mx-0 bg-light rounded p-3">
                      <div className="col-5 col-md-3 col-lg-2">
                        <Link to={`/ja/product/${urlRoute}`}>
                          <span>
                            <strong>{title}</strong>
                          </span>
                        </Link>
                      </div>
                      <div className="col border-left border-secondary">
                        <span className="text_limit1">{excerpt}</span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
      <section id="result_case" className="my-3">
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <p className="h3">
                <strong>{LANG.成功案例}</strong>
              </p>
            </div>
            {filteredCases.length === 0 && (
              <div className="col-12 my-1 align-items-center">
                <p>{LANG.未找到相符的內容}</p>
              </div>
            )}
            {filteredCases.map(
              ({
                node: {
                  frontmatter: { title, thumbnail },
                  fields: { slug },
                  excerpt,
                },
              }) => {
                return (
                  <div className="col-6 col-md-4 col-lg-3 mb-3 rf1">
                    <Link to={`/ja/case/${slug}`}>
                      <div className="card text-secondary">
                        <div
                          className="hover-zoom rounded-top"
                          style={{ backgroundImage: `url(${thumbnail.publicURL})` }}
                        >
                          <img src={Gallery} className="card-img-top" />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                            {title}
                          </h5>
                          <p className="card-text text_limit2">{excerpt}</p>
                          <span className="btn-sm btn-primary">{LANG.了解案例}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </JaLayout>
  );
};

export default Search;
